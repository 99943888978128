export const useTracking = () => {
  const { $dataLayer } = useNuxtApp()

  const trackClick = (payload: any) => $dataLayer.linkClick(payload)
  const trackView = (payload: any) => $dataLayer.pageView(payload)

  return {
    trackClick,
    trackView
  }
}
